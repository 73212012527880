import React from 'react';

import SmallSpacer from '../spacer/small-spacer';

import { GatsbyImage } from 'gatsby-plugin-image';




function Stat({ stat: { name, image } }) {


    return (

        <div className=''>
            <div className='mb20  pt50 pos-rel '>
                <GatsbyImage alt="" className='r6 bg-image' image={image.gatsbyImageData} />
            </div>
            <h5 className='max-325'>
                {name}
            </h5>

        </div>
    )
}

export default function Stats({ stats }) {

    return (
        <div className='max-1600 ma bt1'>
            <SmallSpacer />

            <div className='flex ju-sb stats--grid'>
                {stats.map((stat) => {
                    return <Stat stat={stat} />
                })}
            </div>

        </div>
    )
}
