import React from 'react'
import SmallSpacer from '../spacer/small-spacer'

export default function Infographic({infoGraphics,title}) {
    return (
        <div className='flex m-column m-reverse'>
            <div className='flex-grow-1 flex flex-c  '>
                {infoGraphics.map(({title,icon,subLine}) =>{
                    return (
                        <div className=''>
                            <div className='flex al-i-c mb2'>{icon} <h6 className='small-serif ml10 text-suede'>{title}</h6></div>
                            <h6 className='small-serif max-325'>{subLine}</h6>
                            <SmallSpacer/>
                            <SmallSpacer/>
                        </div>
                    )
                })}
            </div>
            <SmallSpacer className='m-show'/>
            <div>
                <h4 className='max-500'>{title}</h4>
            </div>
        </div>
    )
}
