import React from 'react'

export default function Star({fill = "#C7B8A6"}) {
    return (
        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9351 3.08474C12.4044 3.3511 12.4044 4.02728 11.9351 4.29364L6.84384 7.18364C6.63107 7.30442 6.37047 7.30443 6.15768 7.18368L1.06517 4.29367C0.595843 4.02733 0.595844 3.35105 1.06517 3.0847L6.15768 0.194701C6.37047 0.0739473 6.63107 0.0739607 6.84384 0.194737L11.9351 3.08474Z" fill={fill}/>
        </svg>
        
    )
}
