import React from 'react'
import ContentContainer from './content-container'

export default function ContentContainerInset({children}) {
    return (
        <div className='max-1200 ma'>
            <ContentContainer>
                {children}
            </ContentContainer>
        </div>
    )
}
