import React from 'react'

export default function Location({ fill = '#ff6326 ' }) {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.45788 1.07471H3.54195L1.08398 5.68768L5.99992 12.8417L10.9158 5.68768L8.45788 1.07471Z" stroke='#ff6326 ' stroke-miterlimit="10" />
            <path d="M1.08398 12.9253H10.9158" stroke='#ff6326 ' stroke-miterlimit="10" />
            <path d="M5.99989 5.82802C6.30995 5.82802 6.5613 5.56451 6.5613 5.23945C6.5613 4.91439 6.30995 4.65088 5.99989 4.65088C5.68983 4.65088 5.43848 4.91439 5.43848 5.23945C5.43848 5.56451 5.68983 5.82802 5.99989 5.82802Z" stroke='#ff6326 ' stroke-miterlimit="10" />
        </svg>

    )
}
