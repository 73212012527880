
import React from 'react'
import ContentContainerInset from '../components/content-container/content-container-inset'
import Infographic from '../components/infographic/infographic'
import Layout from '../components/layout'
import ScalingBanner from '../components/scaling-banner/scaling-banner'
import Seo from '../components/seo'
import Cattle from '../icons/operations/cattle'
import SmallSpacer from '../components/spacer/small-spacer'
import OperationsSlider from '../components/operations/operations-slider'
import Spacer from '../components/spacer/spacer'
import TitleList from '../components/title/title-list'
import SquareScalingBanner from '../components/square-scaling-banner/square-scaling-banner'
import Location from '../icons/location'
import Stats from '../components/operations/stats'
import ContentContainer from '../components/content-container/content-container'
import FadeInView from '../components/fade-inview/fade-inview'
import { graphql } from 'gatsby'
import Marker from '../images/marker'


export default function Operations({data:{operation}}) {

    const infographics = [
        {
            title: 'Licensed Capacity',
            icon: <Cattle />,
            subLine: operation.cattle
        },
        {
            title: 'Location',
            icon: <Location />,
            subLine: operation.location
        }
    ]

    

    return (
        <Layout>
            <Seo title={operation.name}   />
            <ScalingBanner hide={true} video={operation.videoLink} image={operation.heroImage} title='operation' />
            <ContentContainerInset>
                <Spacer/>
                <h1 className='text-center'>{operation.name}</h1>  
                <Spacer className='m-h-30' />
                <div className='flex ju-c'>
                <Marker color="FF5A3A"/>
                </div>
                <Spacer className='m-h-30' />
                <SmallSpacer className='m-hide' /> 
                <Infographic title={operation.byline} infoGraphics={infographics} />
                <SmallSpacer className='m-hide'  />
                <SmallSpacer />
                <FadeInView>
                <OperationsSlider images={operation.gallery.map((image) => image.url)} />
                </FadeInView>
                <Spacer />
                <TitleList  heading={operation.description[0].heading} paragraphs={operation.description[0].paragraphs}  />
                <Spacer />
                <SmallSpacer className='m-hide' />
                <SmallSpacer className='m-hide' />
            </ContentContainerInset>
            <ContentContainer>
                <FadeInView>
                    <Stats stats={operation.stats} />
                </FadeInView>
                <SmallSpacer />
                <Spacer/>
               
            </ContentContainer>
            <SquareScalingBanner image={operation.nextOperation.heroImage} link={`/operations/${operation.nextOperation.slug}`}>
                <h3 className='small-serif'>Next Page</h3>
                <SmallSpacer />
                <h2 className='h1 m-h3'>{operation.nextOperation.name}</h2>
            </SquareScalingBanner>
            <Spacer/>
           
        </Layout>
    )
}



export const query = graphql`
   query Operation($id:String) {
        operation:datoCmsOperation(id: {eq: $id}) {
            byline
            cattle
            location
            heroImage{
                gatsbyImageData
                url
            }
            videoLink
            stats{
                name
                image{  
                    gatsbyImageData
                }
            }
            name
            gallery{
                url 
            }
            description {
                heading
                paragraphs {
                    name
                    value
                }
            }
            nextOperation {
                ...OperationBanner
            }
        }
    }
`