import { Swiper, SwiperSlide, } from "swiper/react";
import { Navigation, Pagination } from 'swiper';
import React, { useEffect } from "react";
import RightArrow from "../../icons/right-arrow";
import LeftArrow from "../../icons/left-arrow";
import useIndex from "../../utils/useIndex";


const Slider = ({ images, ...props }) => {
    const { setCurrent, current } = useIndex(images.length)

    useEffect(() => {
        document.querySelectorAll('button[data-pag]').forEach((button) => {
            const index = button.dataset.index
            button.addEventListener('click', () => slideTo(index))
        })
        return () =>  document.querySelectorAll('button[data-pag]').forEach((button) => {
            const index = button.dataset.index
            button.removeEventListener('click', () => slideTo(index))
        })
    }, [])

    const swiperOptions = {
        speed: 750,
        grabCursor: true,
        resistanceRatio: 0,
        resistance: false,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        watchSlidesProgress: true,
        modules: [Navigation, Pagination],


        onProgress: function () {

            var swiper = this;


            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * 0.5;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".operation--slide--inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }

        },
        onSlideChange: (swiper) => { setCurrent(swiper.progress); },

        onSetTranslate: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].querySelector(".operation--slide--inner").style.transition = `0ms ease all`;
            }
        },
        onTransitionStart: function (speed) {

            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".operation--slide--inner").style.transition =
                    `750ms ease all`;
            }
        },
    }

    const slideTo = (index) => {
        document.querySelector('.swiper')?.swiper.slideTo(index, 1000, true,);
    }

    return (
        <>


            <div className=" operation--slider--container overflow-hidden bg-wrapper   ma r6 ">
                <Swiper className="bg-image ma"  {...swiperOptions} {...props} >
                    {images.map((image, index) => (
                        <SwiperSlide className="operation--slide" key={index} >
                            <div style={{ backgroundImage: `url(${image})` }} className='operation--slide--inner'></div>
                        </SwiperSlide>
                    ))}

                </Swiper>

            </div>
            <div className="flex w100 al-i-c">
                <span className="caption orange">{current}</span>
                <div className="flex flex-end flex-grow-1 ">
                    <div className='swiper-prev mr10 '>
                        <RightArrow />

                    </div>
                  
                    <div className='swiper-next  '>
                        <LeftArrow />
                    </div>

                </div>
            </div>


        </>

    )
}

export default function OperationsSlider({ images }) {
    return (
        <div>
            <Slider images={images} />
        </div>
    )
}
