import React from 'react'
import Star from '../../icons/star'
import ArrowLink from '../arrow-link/arrow-link'
import ContentContainer from '../content-container/content-container'
import FadeInView from '../fade-inview/fade-inview'
import SmallSpacer from '../spacer/small-spacer'

export default function TitleList({ heading, paragraphs }) {
    return (
        <ContentContainer>
            <div className='flex flex-c al-i-c'>
                <FadeInView>
                    <h6 className='large-sentence max-800 light-white'>{heading}</h6>
                </FadeInView>
                <SmallSpacer />
                <SmallSpacer />
                <Star fill='#ff6326 ' />
                <SmallSpacer />
                <div className='max-600 w-100'>
                    {paragraphs?.map(({ name, value, readMoreLink }) => {
                        return (
                            <FadeInView>
                                <h6 className='small-serif mb20 '>{name}</h6>
                                <p style={{ wordBreak: `break-word` }} className='body-copy'>{value}</p>

                                {(readMoreLink ?
                                    <ArrowLink classMain='mt20 justify-between' link={readMoreLink} name='Read More' />
                                    :
                                    ''
                                )}
                                <SmallSpacer />
                            </FadeInView>
                        )
                    })}

                </div>
            </div>
        </ContentContainer>
    )
}
