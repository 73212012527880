import React from 'react'

export default function Cattle() {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 13L4.56086 10.0571L5.30908 8.08782L5.8079 8.48252L7.23658 8.69025L8.25726 8.41189L9.70898 8.83012L10.6158 8.64455L11.8859 7.57819V7.23058L9.40264 5.35407L8.70592 3.9401L7.57681 3.01362L8.48634 1L6.375 1.71737H5.89872H5.06781L1 3.63543" stroke="#FF5A3A" stroke-miterlimit="10"/>
        </svg>
        
    )
}
